
import { IonPage, IonContent, IonText, modalController } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import VendorNavComponent from "./VendorNavComponent.vue";
import TrackingCodeModal from "@/components/TrackingCodeModal.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import BuyerReview from "@/components/BuyerReview.vue";

export default defineComponent({
  name: "PastOrders",
  setup() {
    const { t, tm, te } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const storeX = useStore();
    const orderId = ref(route.params.orderId);
    const orderDetails = ref(null);
    const orderItems = ref([]);
    const customerId = ref(null);
    const customerReviews = ref([]);

    const getBuyerReview = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getBuyerReview"), {
          customer_id: customerId.value,
          limit: 10,
        })
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            customerReviews.value = resp.items;
          } else {
            customerReviews.value = [];
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const checkTrackingCode = () => {
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("trackingIsObjectAssigned"),
          {
            object_id: orderDetails.value.po_id,
            object_modelname: "egyprotech_epowerscm/purchaseorder",
          }
        )
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            orderDetails.value["assign_trackingcode"] = !resp.data.assigned;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const getSellerOrderDetails = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getSellerOrderDetails"), {
          order_id: orderId.value,
        })
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            const item = resp.marketplace_order;
            item["assign_trackingcode"] = false;
            orderDetails.value = item;
            orderItems.value = resp.sales_items;
            customerId.value = resp.sales_order.customer_id;
            checkTrackingCode();
            getBuyerReview();
          } else {
            orderDetails.value = null;
            orderItems.value = [];
            customerId.value = null;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const openBuyerReview = async (
      customerId: any,
      customerReviews: any,
      orderId: any
    ) => {
      const modal = await modalController.create({
        component: BuyerReview,
        cssClass: "buyerReview",
        componentProps: {
          customerId,
          customerReviews,
          orderId,
          dismiss: () => {
            modal.dismiss();
          },
        },
      });
      return modal.present();
    };

    const openTrackingCodeModal = async () => {
      const modal = await modalController.create({
        component: TrackingCodeModal,
        cssClass: "trackingCodeModal",
        componentProps: {
          orderDetails: orderDetails.value,
          dismiss: (assigned) => {
            modal.dismiss();
            orderDetails.value["assign_trackingcode"] = !assigned;
          },
        },
      });
      return modal.present();
    };

    const ionViewWillEnter = () => {
      getSellerOrderDetails();
    };

    return {
      storeX,
      t,
      tm,
      te,
      router,
      ionViewWillEnter,
      orderDetails,
      orderItems,
      customerId,
      customerReviews,
      openBuyerReview,
      getBuyerReview,
      openTrackingCodeModal,
    };
  },

  components: {
    IonPage,
    IonContent,
    IonText,
    VendorNavComponent,
    MainHeader,
    MainFooter,
  },
});
