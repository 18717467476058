
import {
  IonContent,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonLabel,
  IonInput,
  IonItem,
  IonTextarea,
} from "@ionic/vue";
import { defineComponent, ref, reactive } from "vue";
import { close, star, starOutline } from "ionicons/icons";

//import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import httpApp from "@/libs/http";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import settingsApp from "@/libs/settings";
import { useStore } from "@/store";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  name: "BuyerReview",
  props: {
    customerId: String,
    orderId: String,
    customerReviews: Array,
    dismiss: Function,
  },
  setup(props: any) {
    const { t, te, tm } = useI18n();
    const icons = reactive({
      close,
      star,
      starOutline,
    });
    const storeX = useStore();
    const { openToast, createLoading } = useGlobalFunctions();
    const propCustomerId = ref(props.customerId);
    const propOrderId = ref(props.orderId);
    const propCustomerReviews = ref(props.customerReviews);

    const addReviewData = ref({
      title: "",
      review: "",
      rating: "",
    });

    const addReviewRules = {
      title: { required },
      review: { required },
      rating: { required },
    };

    const vAddReview = useVuelidate(addReviewRules, addReviewData) as any;

    const saveRatingAction = async (event: any) => {
      event.preventDefault();
      vAddReview.value.$touch();
      if (vAddReview.value.$invalid) {
        return false;
      }
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("addBuyerReview"), {
          customer_id: propCustomerId.value,
          order_id: propOrderId.value,
          title: addReviewData.value.title,
          rating: addReviewData.value.rating,
          review: addReviewData.value.review,
        })
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            openToast(t("successMsgs.successAddReview"), "success");
            loading.dismiss();
            addReviewData.value.title = "";
            addReviewData.value.review = "";
            addReviewData.value.rating = "";
          } else {
            if (te("apiMsgs." + resp.error.code)) {
              openToast(t("apiMsgs." + resp.error.code), "danger");
              loading.dismiss();
            } else {
              openToast(resp.error.message, "danger");
              loading.dismiss();
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
          openToast(error.message, "danger");
          loading.dismiss();
        });
    };

    const getStarIcon = (n: any) => {
      const decision =
        n <= addReviewData.value.rating ? icons.star : icons.starOutline;
      return decision;
    };

    const getRatingIconColor = (n: any) => {
      const decision = n <= addReviewData.value.rating ? "primary" : "";
      return decision;
    };

    const setReviewOption = (n: any) => {
      addReviewData.value.rating = n;
    };

    return {
      t,
      tm,
      storeX,
      props,
      icons,
      propCustomerId,
      propCustomerReviews,
      propOrderId,
      addReviewData,
      vAddReview,
      getStarIcon,
      getRatingIconColor,
      setReviewOption,
      saveRatingAction,
    };
  },
  components: {
    IonContent,
    IonRow,
    IonCol,
    IonIcon,
    IonText,
    IonLabel,
    IonInput,
    IonItem,
    IonTextarea,
  },
});
