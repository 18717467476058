import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-9" }
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = { class: "col-lg-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "whiteBackground ion-padding" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.t("account.assignTrackingCode")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              href: "javascript:void(0)",
              class: "close ion-float-end",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.props.dismiss()))
            }, [
              _createVNode(_component_ion_icon, {
                icon: _ctx.icons.close,
                color: "danger"
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        _createVNode(_component_ion_list, { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, {
              class: "form-control",
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_select, {
                  class: "trackingCode",
                  interface: "popover",
                  modelValue: _ctx.selectedTrackingCodeType,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTrackingCodeType) = $event)),
                  placeholder: _ctx.t('account.selectTrackingCodeType')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select_option, { value: "qr_code" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("account.qrCode")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_select_option, { value: "barcode" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("account.barcode")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, {
              class: "form-control",
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.trackingCode,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.trackingCode) = $event)),
                  placeholder: _ctx.t('account.trackingCode')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createElementVNode("a", {
              href: "javascript:void(0)",
              class: "btn btn-heading btn-block",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.trackingCodeScan()))
            }, _toDisplayString(_ctx.t("login.submit")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}