
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { close } from "ionicons/icons";
import settingsApp from "@/libs//settings";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import httpApp from "@/libs/http";

import {
  IonContent,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonIcon,
  IonList,
  IonItem,
} from "@ionic/vue";
export default defineComponent({
  name: "trackingCodeModal",
  props: {
    orderDetails: Object,
    dismiss: Function,
  },
  setup(props: any) {
    const { t, te } = useI18n();
    const { createLoading, openToast } = useGlobalFunctions();
    const icons = reactive({
      close,
    });

    const orderData = ref(props.orderDetails);
    const selectedTrackingCodeType = ref(null);
    const trackingCode = ref(null);

    const trackingCodeScan = async () => {
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("assignTrackingCode"), {
          object_id: orderData.value.po_id,
          object_modelname: "egyprotech_epowerscm/purchaseorder",
          tracking_code: trackingCode.value,
          tracking_code_type: selectedTrackingCodeType.value,
        })
        .then(async (resp: any) => {
          loading.dismiss();
          if (resp.success) {
            props.dismiss(true);
          } else {
            if (te("apiMessages.codes." + resp.error.code)) {
              openToast(t("apiMessages.codes." + resp.error.code), "danger");
              props.dismiss();
            } else {
              openToast(resp.error.message, "danger");
              props.dismiss();
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
          loading.dismiss();
          props.dismiss();
        });
    };

    return {
      t,
      props,
      icons,
      selectedTrackingCodeType,
      trackingCodeScan,
      trackingCode,
    };
  },
  components: {
    IonContent,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonIcon,
    IonList,
    IonItem,
  },
});
